import styled from '@emotion/styled';
import Close from '$icons/close.svg';
import Button, { StyledButton } from '../components/Button';

export const Content = styled.div({
    height: '100%',
    overflow: 'hidden',
});

export const Container = styled.div(({ theme }) => ({
    padding: `${theme.metrics.xsmall}`,
    overflow: 'auto',
    maxHeight: `calc(100vh - ${theme.metrics.xlarge} - ${theme.metrics.large})`,
}));

export const StyledHeader = styled.div(({ theme }) => ({
    height: `${theme.metrics.xlarge}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `0 2px 20px 0 rgba(0,0,0,0.2)`,
    padding: '10px',
    textTransform: 'uppercase',
}));

export const StyledFooter = styled.div(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.metrics.micro}`,
}));

export const StyledTitle = styled.p(({ theme }) => ({
    display: 'flex',
    height: `${theme.metrics.xlarge}`,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    ...theme.mixins.useTextStyle('InfoHeader'),
}));

export const CloseButton = styled(Button)(({ theme }) => ({
    padding: '16px',
    svg: {
        height: `${theme.fontSizes.sm}`,
        width: `${theme.fontSizes.sm}`,
    },
}));
export const StyledClose = styled(Close)({});

export const StyledCTAButton = styled(StyledButton)(({ theme }) => ({
    width: '100%',
    height: `${theme.metrics.medium}`,
}));
