import * as yup from 'yup';
import { Fields } from '../models/fields';
import { useTranslation } from '~/shared/utils/translation';
import { passwordRegex } from '$shared/utils';
import { isAddress1, isPhoneWithoutCountryCode, isWord, isZipCode } from '~/utils/form-validators';
import { checkForCommonPasswordHandler } from '~/shared/utils/commonPasswords';
/**
 * Yup documentaion https://github.com/jquense/yup
 * @returns validation schema
 */

export const useValidationSchema = (isSoMe: boolean) => {
    const { translate } = useTranslation();
    if (!isSoMe) {
        return yup.object().shape({
            [Fields.SoMe]: yup.boolean(),
            [Fields.FirstName]: yup.string().required('').trim().matches(isWord),
            [Fields.LastName]: yup.string().required('').trim().matches(isWord),
            [Fields.Birthday]: yup
                .date()
                .nullable()
                .default(undefined)
                .min('1900-01-01', translate('message.incorrectDate'))
                .max(new Date(), translate('message.dateCannotBeInFuture'))
                .typeError(translate('message.incorrectDate'))
                .transform((curr, orig) => {
                    return orig === '' ? null : curr;
                }),
            [Fields.Gender]: yup.number().nullable(),
            [Fields.Mobile]: yup
                .string()
                .required('')
                .max(8, translate('message.phoneValidationError'))
                .matches(isPhoneWithoutCountryCode, translate('message.phoneValidationError')),
            [Fields.Address]: yup
                .string()
                .notRequired()
                .matches(isAddress1, {
                    message: translate('message.addressMissingHouseNumber'),
                    excludeEmptyString: true,
                })
                .max(35, translate('message.addressLengthValidationError')),
            [Fields.Address2]: yup
                .string()
                .max(35, translate('message.addressLengthValidationError')),
            [Fields.Email]: yup
                .string()
                .email(translate('message.emailValidationError'))
                .required('')
                .max(50, translate('message.inputValidationError.toLong', { length: '50' })),
            [Fields.RepeatEmail]: yup
                .string()
                .email(translate('message.emailValidationError'))
                .required('')
                .oneOf([yup.ref(Fields.Email), null], translate('createAccount.emailMismatch')),
            [Fields.CreateAccountConsent]: yup.boolean().oneOf([true], '').required(''),
            [Fields.Password]: yup
                .string()
                .required('')
                .test('not-common-password', translate('message.noCommonPassword'), (value) =>
                    checkForCommonPasswordHandler(value),
                )
                .matches(passwordRegex, translate('message.passwordValidationError')),

            [Fields.RepeatPassword]: yup
                .string()
                .oneOf(
                    [yup.ref(Fields.Password), null],
                    translate('message.passwordValidationError.dontMatch'),
                ),
            [Fields.PostalCode]: yup
                .string()
                .required('')
                .matches(isZipCode, translate('message.postNumberValidationError')),

            [Fields.City]: yup.string().required(''),
        });
    } else {
        return yup.object().shape({
            [Fields.SoMe]: yup.boolean(),
            [Fields.FirstName]: yup.string().required('').trim().matches(isWord),
            [Fields.LastName]: yup.string().required('').trim().matches(isWord),
            [Fields.Birthday]: yup
                .date()
                .nullable()
                .default(undefined)
                .min('1900-01-01', translate('message.incorrectDate'))
                .max(new Date(), translate('message.dateCannotBeInFuture'))
                .typeError(translate('message.incorrectDate'))
                .transform((curr, orig) => {
                    return orig === '' ? null : curr;
                }),
            [Fields.Gender]: yup.number().required(''),
            [Fields.Mobile]: yup
                .string()
                .required('')
                .max(8, translate('message.phoneValidationError'))
                .matches(isPhoneWithoutCountryCode, translate('message.phoneValidationError')),
            [Fields.Address]: yup
                .string()
                .notRequired()
                .matches(isAddress1, {
                    message: translate('message.addressMissingHouseNumber'),
                    excludeEmptyString: true,
                })
                .max(35, translate('message.addressLengthValidationError')),
            [Fields.Address2]: yup
                .string()
                .max(35, translate('message.addressLengthValidationError')),
            [Fields.CreateAccountConsent]: yup.boolean().oneOf([true], '').required(''),
        });
    }
};
