import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { breakpointsRaw } from '~/theme/breakpoints';
import { mq, P120CreateLoginPage } from '~/lib';
import {
    StyledCTAButton,
    StyledNowrap,
    StyledTable,
    StyledTableRow,
    StyledTableCellRightSpace,
    StyledTableCell,
    StyledMyprofileTextBlock,
} from './styled';
import { useTranslation } from '~/shared/utils/translation';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePage } from '~/templates/pages/hooks/usePage';
import { useValidationSchema } from './lib/useValidationSchema';
import { CreateLoginFields, Fields } from './models/fields';
import { Form, Input, Checkbox } from '~/shared/components/form';
import { Radio, Text } from '~/shared/components';
import Grid from '~/shared/components/Grid/Grid';
import { Container } from '~/shared/components';
import { useMarket } from '~/shared/utils';
import Spacer from '~/theme/design-system/components/Spacer';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { useFrame } from '~/shared/utils/frame';
import { getCityByZipCode } from '~/lib/googlemaps-utils';
import { useRouter } from 'next/router';
import { InfoDrawer } from '~/shared/InfoDrawer';
import Button from '~/shared/components/Button';
import { notifyError, notifyInfo } from '~/shared/utils/errorBoundary/toast';
import { dateToString } from '~/utils/date-to-string';
import useGoogleMapsApi from '../find-store/components/StoreMap/hooks/useGoogleMapsApi';
import { Phone } from '~/shared/components/form/components/form-fields/phone';

type DrawerContentType = {
    title: string;
    content: string;
};
export const CreateLoginPage = () => {
    const { data: frame } = useFrame();
    const countryCode = frame.culture?.split('-')[1].toUpperCase();
    const page = usePage() as P120CreateLoginPage;
    const { translate } = useTranslation();
    const [showDrawer, setShowDrawer] = useState(false);
    const drawerContent = useRef<DrawerContentType>({ title: '', content: '' });
    const [showMemberDrawer, setShowMemberDrawer] = useState(false);
    const { query, replace } = useRouter();
    const isSoMeLogin = !!query.some;
    const validationSchema = useValidationSchema(isSoMeLogin);
    const customer = useCustomer();
    const { market } = useMarket();
    const methods = useForm<CreateLoginFields>({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        resolver: yupResolver(validationSchema),
    });
    const zipCode = methods.watch(Fields.PostalCode);
    const isValid = methods.formState.isValid;

    const { isLoaded } = useGoogleMapsApi();

    const clickHandler = (title: string, content: string) => {
        drawerContent.current = { title, content };
        setShowDrawer(true);
    };

    const clickMemberHandler = (title: string, content: string) => {
        drawerContent.current = { title, content };
        setShowMemberDrawer(true);
    };

    const handleSubmit = async (payload: CreateLoginFields) => {
        const request = {
            customer: {
                firstName: payload.firstname,
                lastName: payload.lastname,
                email: payload.email ?? customer?.email,
                birthday: (payload.birthday as unknown as Date)
                    ? dateToString(payload.birthday as unknown as Date)
                    : undefined,
                gender: payload.gender ? parseInt(payload.gender) : undefined,
                address: payload.address,
                postalCode: payload.postalCode,
                city: payload.city,
                phone: payload.mobile,
                c_acceptedTermsAndConditions: payload.c_acceptedTermsAndConditions,
                c_completeRegistration: true,
                /**
                 * The signup terms says that the user automatically
                 * accepts marketing
                 */
                c_newsletter: true,
                c_socialMedia: true,
            },
            password: payload.password,
        };

        const address = {
            addressId: 'main',
            countryCode: countryCode!,
            firstName: payload.firstname,
            lastName: payload.lastname,
            address1: payload.address,
            address2: payload.address2,
            postalCode: payload.postalCode,
            city: payload.city,
            phone: payload.mobile,
        };

        if (isSoMeLogin) {
            await customer.updateCustomer({ ...request.customer, addresses: [address] });
        } else {
            try {
                await customer.registerCustomer({ ...request, addresses: [address] });
            } catch (e) {
                if (
                    e instanceof Error &&
                    (e.message.includes('The login is already in use') ||
                        e.message.includes('The customer is already registered'))
                ) {
                    notifyError(new Error(translate('createAccount.loginAlreadyInUse')));
                } else {
                    notifyError(new Error(translate('message.generalErrorMessage')));
                }
                return;
            }
        }

        notifyInfo(translate('createAccount.accountCreated'));

        replace(frame.settings?.pageReferences.myProfilePage?.url ?? '/');
    };

    useEffect(() => {
        setCityFromZipCode(zipCode, market);
    }, [zipCode, market]);

    useEffect(() => {
        if (customer?.c_isMemberPlus) {
            replace(frame.settings?.pageReferences.myProfilePage?.url ?? '/');
        }
    }, [customer]);

    const setCityFromZipCode = async (zipCode: string, market: string) => {
        if (zipCode?.length === 4 && parseInt(zipCode, 10) > 0) {
            const city = await getCityByZipCode(parseInt(zipCode, 10), market);
            if (city) {
                methods.setValue(Fields.City, city);
            } else {
                methods.setError(Fields.PostalCode, {
                    message: translate('message.postNumberValidationError.notFound'),
                });
                methods.setValue(Fields.City, '');
                methods.setValue(Fields.PostalCode, '');
            }
        }
    };

    if (!isLoaded) {
        return <></>;
    }

    return (
        <Container hasVerticalGutter hasGutter>
            <Grid
                flow="column"
                columns="50%"
                columnGap="large"
                breakpoints={{
                    [mq(0, breakpointsRaw.md)]: {
                        columns: '1fr',
                        rowGap: 'small',
                        flow: 'row',
                    },
                }}
                style={{ width: '100%' }}
            >
                <Grid flow="column" columns="1fr" columnGap="extrasmall">
                    <Form methods={methods} onSubmit={handleSubmit}>
                        <Grid flow="row" rowGap="extrasmall">
                            <h4>{translate('createAccount.becomeMemberOfImerco')}</h4>
                        </Grid>
                        <StyledMyprofileTextBlock
                            dangerouslySetInnerHTML={{ __html: page.createLoginText }}
                        ></StyledMyprofileTextBlock>
                        <Grid flow="row" rowGap="extrasmall">
                            <Input
                                type="hidden"
                                name={Fields.SoMe}
                                value={isSoMeLogin ? 'true' : 'false'}
                            />
                            <Input
                                data-testid="firstname"
                                name={Fields.FirstName}
                                label={translate('createAccount.firstName')}
                                maxLength={24}
                                required
                            />
                            <Input
                                data-testid="lastname"
                                name={Fields.LastName}
                                label={translate('createAccount.lastName')}
                                maxLength={24}
                                required
                            />

                            <Phone
                                data-testid="phone-input"
                                name={Fields.Mobile}
                                label={translate('createAccount.mobile')}
                                maxCharacters={8}
                                required
                            />

                            {!isSoMeLogin && (
                                <Input
                                    data-testid="email"
                                    name={Fields.Email}
                                    label={translate('createAccount.mail')}
                                    maxLength={50}
                                    required
                                />
                            )}
                            {!isSoMeLogin && (
                                <Input
                                    data-testid="repeat-email"
                                    name={Fields.RepeatEmail}
                                    label={translate('createAccount.repeatMail')}
                                    maxLength={50}
                                    required
                                />
                            )}

                            <Input
                                data-testid="address"
                                name={Fields.Address}
                                label={translate('createAccount.address')}
                                maxLength={50}
                            />

                            <Grid flow="column" columns="1fr 2fr" columnGap="extrasmall">
                                <Input
                                    data-testid="postalCode"
                                    name={Fields.PostalCode}
                                    label={translate('createAccount.zip')}
                                    maxLength={4}
                                    required
                                />
                                <Input
                                    data-testid="city"
                                    name={Fields.City}
                                    disabled
                                    label={translate('createAccount.city')}
                                    required
                                />
                            </Grid>
                            <StyledTable>
                                <StyledTableRow>
                                    <StyledTableCell>
                                        <Radio
                                            data-testid="gender-input"
                                            type="radio"
                                            name={Fields.Gender}
                                            value="2"
                                        />
                                    </StyledTableCell>
                                    <StyledTableCellRightSpace>
                                        {translate('createAccount.female')}
                                    </StyledTableCellRightSpace>
                                    <StyledTableCell>
                                        <Radio
                                            data-testid="gender-input"
                                            type="radio"
                                            name={Fields.Gender}
                                            value="1"
                                        />
                                    </StyledTableCell>
                                    <StyledTableCellRightSpace>
                                        {translate('createAccount.male')}
                                    </StyledTableCellRightSpace>
                                    <StyledTableCell>
                                        <Radio
                                            data-testid="gender-input"
                                            type="radio"
                                            name={Fields.Gender}
                                            value="3"
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {translate('createAccount.genderOther')}
                                    </StyledTableCell>
                                </StyledTableRow>
                            </StyledTable>
                            <Input
                                data-testid="birthday"
                                type="date"
                                name={Fields.Birthday}
                                label={translate('createAccount.birthday')}
                            />
                            <Text variant="bodySmall">
                                {translate('createAccount.weAskAboutYourBirthday')}
                            </Text>
                            {!isSoMeLogin && (
                                <Input
                                    data-testid="password"
                                    name={Fields.Password}
                                    type="password"
                                    label={translate('login.password')}
                                    required
                                />
                            )}
                            {!isSoMeLogin && (
                                <Input
                                    data-testid="repeat-password"
                                    name={Fields.RepeatPassword}
                                    type="password"
                                    label={translate('createAccount.repeatPassword')}
                                    required
                                />
                            )}
                            <br />
                            <Checkbox
                                data-testid="accept-terms"
                                name={Fields.CreateAccountConsent}
                                label={translate('createAccount.acceptImercoPlusMembership')}
                                required
                            />
                            <br />
                            <Grid flow="row">
                                <StyledNowrap>
                                    {translate('createAccount.readAboutImercos')}&nbsp;
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            clickHandler(
                                                frame.market?.personalDataPolicyLabel ?? '',
                                                frame.market?.personalDataPolicyContent ?? '',
                                            );
                                        }}
                                    >
                                        {translate('createAccount.handlingOfPersonData')}
                                    </Button>
                                </StyledNowrap>
                            </Grid>
                            <Grid flow="row">
                                <StyledNowrap>
                                    {translate('createAccount.readAbout')}&nbsp;
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            clickMemberHandler(
                                                frame.market?.membershipTermsConsentLabel ?? '',
                                                frame.market?.membershipTermsContent ?? '',
                                            );
                                        }}
                                    >
                                        {translate('createAccount.membershipTermsForImercoPlus')}
                                    </Button>
                                </StyledNowrap>
                            </Grid>
                            <StyledCTAButton
                                data-testid="submit-registration"
                                type="submit"
                                variant="primary"
                                disabled={!isValid}
                            >
                                {translate('createAccount.becomeMember')}
                            </StyledCTAButton>
                            <Grid flow="row">
                                {translate('createAccount.rememberMembershipCard')}
                            </Grid>
                        </Grid>
                    </Form>
                </Grid>
                <Grid flow="column" columns="1fr 1fr" columnGap="extrasmall"></Grid>
                <Spacer space="100px" />
                <InfoDrawer
                    title={drawerContent?.current?.title}
                    open={showDrawer}
                    close={() => setShowDrawer(false)}
                    ctaTitle={translate('checkout.consent.button.text')}
                >
                    <div dangerouslySetInnerHTML={{ __html: drawerContent?.current?.content }} />
                </InfoDrawer>
                <InfoDrawer
                    title={drawerContent?.current?.title}
                    open={showMemberDrawer}
                    close={() => setShowMemberDrawer(false)}
                    ctaTitle={translate('checkout.consent.button.text')}
                >
                    <div dangerouslySetInnerHTML={{ __html: drawerContent?.current?.content }} />
                </InfoDrawer>
            </Grid>
        </Container>
    );
};
