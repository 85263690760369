import { FC, useEffect } from 'react';
import { Drawer } from '~/shared/components/Drawer';
import { useScrollLock } from '~/shared/hooks';

import {
    CloseButton,
    Container,
    Content,
    StyledClose,
    StyledCTAButton,
    StyledFooter,
    StyledHeader,
    StyledTitle,
} from './styled';

type InfoDrawerProps = {
    children: React.ReactNode;
    title: string;
    open: boolean;
    ctaTitle?: string;
    close: () => void;
    onCtaClick?: (value?: boolean) => void;
};

export const InfoDrawer: FC<InfoDrawerProps> = ({
    children,
    title,
    open,
    ctaTitle,
    close,
    onCtaClick,
}) => {
    const { lock, unlock } = useScrollLock();

    useEffect(() => {
        if (open) {
            lock();
        }
        return () => unlock();
    }, [lock, unlock, open]);

    return (
        <Drawer open={open} onClose={close} isInfo>
            <Content>
                <StyledHeader>
                    <StyledTitle>{title}</StyledTitle>
                    <CloseButton aria-label="Close" variant="plain" onClick={close}>
                        <StyledClose />
                    </CloseButton>
                </StyledHeader>
                <Container>{children}</Container>
                <StyledFooter>
                    {onCtaClick && ctaTitle && (
                        <StyledCTAButton variant="primary" onClick={() => onCtaClick(true)}>
                            {ctaTitle}
                        </StyledCTAButton>
                    )}
                </StyledFooter>
            </Content>
        </Drawer>
    );
};
export default InfoDrawer;
