import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';

export const StyledContent = styled.div(({ theme: { space } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `0`,
    width: '100%',
    [breakpoints.md]: {
        flexDirection: 'row',
        margin: '0 auto',
        padding: `0 ${space[6]}`,
    },
}));
