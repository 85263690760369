import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

type SpacerVariant = 'horizontal' | 'vertical';
interface SpacerProps {
    space?: string;
    width?: number;
    variant?: SpacerVariant;
}

export const Spacer = styled.div(
    ({ space = '40px' }: SpacerProps) => ({
        marginTop: space,
        width: 0,
    }),
    switchProp('variant', {
        horizontal: ({ space }) => ({
            width: space,
            marginTop: 0,
            display: 'inline-block',
        }),
    })
);

export default Spacer;
