import React from 'react';
import { Modules, P120CreateLoginPage as P120CreateLoginPageType } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { MaxWidth } from '$shared/components';
import { StyledContent } from './styled';
import { CreateLoginPage } from '~/features/create-login/CreateLoginPage';

type P120CreateLoginPageProps = P120CreateLoginPageType;

export const P120CreateLoginPage = (props: P120CreateLoginPageProps) => (
    <>
        <DynamicBlockList elements={props.pageElements as Modules[]} />
        <MaxWidth>
            <StyledContent>
                <CreateLoginPage />
            </StyledContent>
        </MaxWidth>
        <DynamicBlockList elements={props.bottomPageElements as Modules[]} />
    </>
);
