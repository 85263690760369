export enum Fields {
    FirstName = 'firstname',
    LastName = 'lastname',
    Mobile = 'mobile',
    Email = 'email',
    RepeatEmail = 'repeatemail',
    Address = 'address',
    Address2 = 'address2',
    PostalCode = 'postalCode',
    City = 'city',
    Gender = 'gender',
    Birthday = 'birthday',
    Password = 'password',
    RepeatPassword = 'repeatpassword',
    CreateAccountConsent = 'c_acceptedTermsAndConditions',
    SoMe = 'soMe',
    Newsletters = 'c_newsletter',
    SocialMedia = 'c_socialMedia',
}

export type CreateLoginFields = {
    [Fields.FirstName]: string;
    [Fields.LastName]: string;
    [Fields.Mobile]: string;
    [Fields.Email]: string;
    [Fields.RepeatEmail]: string;
    [Fields.Address]: string;
    [Fields.Address2]: string;
    [Fields.PostalCode]: string;
    [Fields.City]: string;
    [Fields.Gender]: string;
    [Fields.Birthday]: string;
    [Fields.Password]: string;
    [Fields.RepeatPassword]: string;
    [Fields.CreateAccountConsent]: boolean;
    [Fields.SoMe]: boolean;
    [Fields.Newsletters]: boolean;
    [Fields.SocialMedia]: boolean;
};
