import styled from '@emotion/styled';
import { StyledButton } from '../../shared/components/Button';

export const StyledCTAButton = styled(StyledButton)(({ theme }) => ({
    width: '100%',
    height: `${theme.metrics.medium}`,
    marginTop: '20px',
    textTransform: 'uppercase',
}));

export const StyledNowrap = styled.span(() => ({
    whiteSpace: 'nowrap',
}));

export const StyledMyprofileTextBlock = styled.p(() => ({
    display: 'block',
    tableLayout: 'auto',
    margin: '20px 0px 20px 0px',
}));

export const StyledTable = styled.div(() => ({
    display: 'inline',
    tableLayout: 'auto',
}));
export const StyledTableRow = styled.div(() => ({
    display: 'table-row',
}));
export const StyledTableCell = styled.div(() => ({
    display: 'table-cell',
    verticalAlign: 'middle',
}));
export const StyledTableCellRightSpace = styled.div(() => ({
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: '30px',
}));
